import React, { Component } from 'react';
import '../styles/footer.css';
import SocialIcons from './SocialIcons';
import { Link } from 'react-router-dom';
export default class MobileFooter extends Component {
  render() {
    return (
      <div className='mobile-footer'>
        <div className='pure-g mobile-footer--row'>
          <div className='pure-u-1-3 mobile-footer--logo'>
            <img
              alt='JVM Sports logo'
              src={require('../assets/images/jvmSportsLogo.png')}
            />
          </div>
          <div className='pure-u-2-3 social-icons'>
            <SocialIcons />
          </div>
        </div>
        <div className='pure-g mobile-footer--row'>
          <div className='pure-u-1-3 footer__address'>
            <span>Glashüttenstraße 79</span>
            <span>20357 Hamburg, Germany</span>
          </div>
          <div className='pure-u-1-3'>
            <span> Phone: +49 40 4321-0</span>
            <br />
            <a href='mailto:sports-press@jvm.de'>sports-press@jvm.de</a>
          </div>
          <div className='pure-u-1-3'>
            <span>For press inquiries please contact </span>
            <br />

            <a href='mailto:sports-press@jvm.de'>sports-press@jvm.de</a>
          </div>
        </div>
        <div className='pure-g'>
          <div className='pure-u-2-3'>
            <Link to='/imprint' className='social-icons--link'>
              Imprint
            </Link>
          </div>
          <div className='pure-u-1-3'>
            <Link to='/privacy' className='social-icons--link'>
              Privacy
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
