import React from 'react';
import '../styles/newsletter.css';
import ThesisEN from '../assets/Equal-Play-Thesis-EN.pdf';
import StudyEN from '../assets/Equal-Play-Study-EN.pdf';
import ThesisGER from '../assets/Equal-Play-Thesis-GER.pdf';
import StudyGER from '../assets/Equal-Play-Study-GER.pdf';

class Newsletter extends React.Component {
  render() {
    return (
      <div className='newsletter'>
        <div className='newsletter__headline'>
          <h2 className='newsletter__headline--title'>Studie herunterladen:</h2>
        </div>

        <h2 className='newsletter__headline--title'>Deutsch</h2>
        <div className='newsletter__download--wrapper'>
          <button className='newsletter__download--button'>
            <a href={ThesisGER} download className='newsletter__download--link'>
              Download Thesenpapier
            </a>
          </button>
          <button className='newsletter__download--button '>
            <a href={StudyGER} download className='newsletter__download--link'>
              Download Studie
            </a>
          </button>
        </div>
        <h2 className='newsletter__headline--title'>English</h2>
        <div className='newsletter__download--wrapper'>
          <button className='newsletter__download--button'>
            <a href={ThesisEN} download className='newsletter__download--link'>
              Download thesis
            </a>
          </button>
          <button className='newsletter__download--button '>
            <a href={StudyEN} download className='newsletter__download--link'>
              Download study
            </a>
          </button>
        </div>
      </div>
    );
  }
}

export default Newsletter;
