import React from 'react';
import '../styles/about.css';
import SpecialButton from '../components/SpecialButton';
import { translations } from '../constants/lang/translation';
import { useTranslation } from 'react-i18next';

function About() {
  const { t } = useTranslation();
  return (
    <div className='about' id='about'>
      <div className='about__headline'>
        <h1 className='about__headline--title'>
          {t(translations.pages.about.headline)}
        </h1>
      </div>
      <p className='about__text'>{t(translations.pages.about.text1)}</p>
      <p className='about__text'>{t(translations.pages.about.text2)}</p>
      <h2 className='about__subheadline'>
        {' '}
        {t(translations.pages.about.supporters)}
      </h2>
      {/* Logo area */}
      <div className='pure-g about__partner--wrapper'>
        <div className='pure-u-1 '>
          <img
            src={require('../assets/images/odgers-berndtson-logo.png')}
            className='about__partner--logo'
            alt=''
          />
        </div>
        {/* <div className='pure-u-1-3'>
          <img
            src={require('../assets/images/jvmSportsLogo.png')}
            className='about__partner--logo'
            alt=''
          />
        </div>
        <div className='pure-u-1-3'>
          <img
            src={require('../assets/images/jvmSportsLogo.png')}
            className='about__partner--logo'
            alt=''
          />
        </div> */}
      </div>

      {/* <h2 className='about__subheadline'>
        {' '}
        {t(translations.pages.about.clients)}
      </h2>

      <div className='pure-g about__partner--wrapper'>
        <div className='pure-u-1-3 about__partner--logo-wrapper'>
          <img
            src={require('../assets/images/jvmSportsLogo.png')}
            className='about__partner--logo'
            alt=''
          />
        </div>
        <div className='pure-u-1-3  about__partner--logo-wrapper'>
          <img
            src={require('../assets/images/jvmSportsLogo.png')}
            className='about__partner--logo'
            alt=''
          />
        </div>
        <div className='pure-u-1-3  about__partner--logo-wrapper'>
          <img
            src={require('../assets/images/jvmSportsLogo.png')}
            className='about__partner--logo'
            alt=''
          />
        </div>
        <div className='pure-u-1-3  about__partner--logo-wrapper'>
          <img
            src={require('../assets/images/jvmSportsLogo.png')}
            className='about__partner--logo'
            alt=''
          />
        </div>
        <div className='pure-u-1-3  about__partner--logo-wrapper'>
          <img
            src={require('../assets/images/jvmSportsLogo.png')}
            className='about__partner--logo'
            alt=''
          />
        </div>
        <div className='pure-u-1-3  about__partner--logo-wrapper'>
          <img
            src={require('../assets/images/jvmSportsLogo.png')}
            className='about__partner--logo'
            alt=''
          />
        </div>
      </div> */}

      <h2 className='about__subheadline'>
        {' '}
        {t(translations.pages.about.expertise.title)}
      </h2>
      <p className='about__expertise'>
        {' '}
        - {t(translations.pages.about.expertise.strategy)} -
      </p>
      <p className='about__expertise'>
        {' '}
        - {t(translations.pages.about.expertise.branding)} -
      </p>
      <p className='about__expertise'>
        {' '}
        - {t(translations.pages.about.expertise.campaigning)} -
      </p>
      <p className='about__expertise'>
        {' '}
        - {t(translations.pages.about.expertise.content)} -
      </p>
      <p className='about__expertise'>
        {' '}
        - {t(translations.pages.about.expertise.social)} -
      </p>
      <p className='about__expertise'>
        {' '}
        - {t(translations.pages.about.expertise.public)} -
      </p>
      <p className='about__expertise'>
        {' '}
        - {t(translations.pages.about.expertise.sponsoring)} -
      </p>
      <p className='about__expertise'>
        {' '}
        - {t(translations.pages.about.expertise.esports)} -
      </p>
      <p className='about__expertise'>
        {' '}
        - {t(translations.pages.about.expertise.platforms)} -
      </p>
      <p className='about__expertise'>
        {' '}
        - {t(translations.pages.about.expertise.events)} -
      </p>
      <SpecialButton
        title={t(translations.actions.contact)}
        link='mailto: sports-press@jvm.de'
      />
    </div>
  );
}

export default About;
