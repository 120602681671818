import React, { Component } from 'react';

export default class Imprint extends Component {
  render() {
    return (
      <div className='wrapper'>
        <br />
        <h1>Imprint</h1>
        <h2>Jung von Matt/SPORTS GmbH</h2>
        <p>
          Glashüttenstraße 79 <br />
          20357 Hamburg <br />
          Germany
          <br />
          Phone: +49 40 4321-0 <br />
          Fax: +49 40 4321-2915 <br />
          E-Mail:{' '}
          <a href='mailto:sports-press@jvm.de' style={{ color: '#fff' }}>
            sports-press@jvm.de
          </a>{' '}
          <br />
          Internet:{' '}
          <a href='https://jvmsports.de/' style={{ color: '#fff' }}>
            https://jvmsports.de
          </a>
        </p>
        <h2>Managing Directors</h2>
        <p>Holger Hansen, Katja Kraus, Robert Zitzmann</p>
        <h2>Registration office</h2>
        <p>Amtsgericht Hamburg</p>
        <h2>Registration number</h2>
        <p>HRB 128471</p>
        <h2>
          VAT registration number pursuant to paragraph 27a of the Value Added
          Tax Law
        </h2>
        <p>DE 290 833 127</p>
        <h2>Links.</h2>
        <p>
          jvmsports.de contains or has Links. Despite thorough control of the
          content, we assume no liability for the content of external Links. The
          operator of the linked pages is exclusively responsible for their
          content.
        </p>
        <h2>Social Media profiles</h2>
        <p>
          This imprint also applies to the following social media presences and
          online profiles:
        </p>
        <ul>
          {' '}
          <li>
            <a
              href='https://www.facebook.com/JungvonMattsports/'
              target='_blank'
              rel='noopener noreferrer'
              style={{ color: '#fff' }}
            >
              https://www.facebook.com/JungvonMattsports/
            </a>
          </li>{' '}
          <li>
            <a
              href='https://www.instagram.com/jvmsports/'
              target='_blank'
              rel='noopener noreferrer'
              style={{ color: '#fff' }}
            >
              https://www.instagram.com/jvmsports/
            </a>
          </li>{' '}
          <li>
            <a
              href='https://twitter.com/jungvmattsports'
              target='_blank'
              rel='noopener noreferrer'
              style={{ color: '#fff' }}
            >
              https://twitter.com/jungvmattsports
            </a>
          </li>{' '}
          <li>
            <a
              href='https://www.linkedin.com/company/jung-von-matt-sports/'
              target='_blank'
              rel='noopener noreferrer'
              style={{ color: '#fff' }}
            >
              https://www.linkedin.com/company/jung-von-matt-sports/
            </a>
          </li>{' '}
        </ul>{' '}
      </div>
    );
  }
}
