import React, { Component } from "react";
import "../styles/footer.css";
import SocialIcons from "./SocialIcons";
import { Link } from "react-router-dom";

export default class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="pure-u-1-3 footer--logo">
          <img
            alt="JVM Sports logo"
            src={require("../assets/images/jvmSportsLogo.png")}
          />
        </div>
        <div className="pure-u-5-24 footer__address">
          <span>Glashüttenstraße 79</span>
          <span>20357 Hamburg, Germany</span>
          <span> Phone: +49 40 4321-0</span>
        </div>
        <div className="pure-u-5-24">
          <span>For press inquiries please contact</span>
          <br />
          <a href="mailto:sports-press@jvm.de">sports-press@jvm.de</a>
        </div>
        <div className="pure-u-1-4 social-icons">
          <SocialIcons />
          <Link to="/imprint" className="social-icons--link">
            Imprint
          </Link>
          <Link to="/privacy" className="social-icons--link">
            Privacy
          </Link>
        </div>
      </div>
    );
  }
}
