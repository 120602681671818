// This file is autogenerated, do not modify manually, use 'npm run buildTranslationsObject'
export const translations = {
  pages: {
    landingpage: { headline: "pages.landingpage.headline" },
    about: {
      title: "pages.about.title",
      headline: "pages.about.headline",
      text1: "pages.about.text1",
      text2: "pages.about.text2",
      supporters: "pages.about.supporters",
      clients: "pages.about.clients",
      expertise: {
        title: "pages.about.expertise.title",
        strategy: "pages.about.expertise.strategy",
        branding: "pages.about.expertise.branding",
        campaigning: "pages.about.expertise.campaigning",
        content: "pages.about.expertise.content",
        social: "pages.about.expertise.social",
        public: "pages.about.expertise.public",
        sponsoring: "pages.about.expertise.sponsoring",
        esports: "pages.about.expertise.esports",
        platforms: "pages.about.expertise.platforms",
        events: "pages.about.expertise.events"
      }
    },
    contact: {
      title: "pages.contact.title",
      headline: "pages.contact.headline",
      form: {
        emailAdress: "pages.contact.form.emailAdress",
        firstName: "pages.contact.form.firstName",
        lastName: "pages.contact.form.lastName",
        required: "pages.contact.form.required"
      },
      marketing: {
        headline: "pages.contact.marketing.headline",
        subheadline: "pages.contact.marketing.subheadline",
        options: { email: "pages.contact.marketing.options.email" },
        unsubscribe: "pages.contact.marketing.unsubscribe",
        mailChimp: {
          info: "pages.contact.marketing.mailChimp.info",
          link: "pages.contact.marketing.mailChimp.link"
        }
      }
    },
    download: { title: "pages.download.title" }
  },
  actions: {
    download: "actions.download",
    study: "actions.study",
    thesis: "actions.thesis",
    contact: "actions.contact"
  },
  header: { study: "header.study" }
};
