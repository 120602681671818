import i18next from "i18next";
import languageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
i18next
  .use(languageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: {},
    resources: {
      de: { translation: require("./de.json") },
      en: { translation: require("./en.json") }
    },
    lng: navigator.language,
    fallbackLng: "de",
    debug: true
  });
export default i18next;
