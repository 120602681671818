import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/header.css';
import MobileNavigation from './MobileNavigation';
import { useTranslation } from 'react-i18next';
import { translations } from '../constants/lang/translation';

export default function Header() {
  const { t } = useTranslation();
  return (
    <div className='header pure-g'>
      <div className='pure-u-lg-1-3 hide-mobile'></div>
      <div className='pure-u-1-2 pure-u-lg-1-3 header--logo-wrapper'>
        <a href='/'>
          <img
            src={require('../assets/images/jvmSportsLogo.png')}
            alt='Jung von Matt Sports Logo'
            className='header--logo'
          />
        </a>
      </div>
      <div className='pure-u-1-2 pure-u-lg-1-3 hide-desktop'>
        <MobileNavigation />
      </div>
      <ul className='pure-u-lg-1-3 header--navigation hide-mobile'>
        <li>
          <Link to={'/about'} className='nav-link'>
            {t(translations.pages.about.title)}
          </Link>
        </li>
        {/* <li>
          <Link to={'/download'} className='nav-link'>
            {t(translations.pages.download.title)}
          </Link>
        </li> */}
        <li>
          <a href='mailto: sports-press@jvm.de' className='nav-link'>
            {t(translations.actions.contact)}
          </a>
        </li>
      </ul>
    </div>
  );
}
