import React from 'react';
import '../styles/subscribe.css';

function createMarkup() {
  return {
    __html: `
    <div id="mc_embed_signup">
<form action="https://equal-play.us20.list-manage.com/subscribe/post?u=2eded37268a4b45ec83412a69&amp;id=57eaef5d2b" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
    <div id="mc_embed_signup_scroll">  

<div class="mc-field-group">
    <label for="mce-EMAIL">E-Mail Addresse  <span class="asterisk">*</span>
</label>
    <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
</div>
<div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
<div id="mergeRow-gdpr" class="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group">
    <div class="content__gdpr">
        <label>Erlaubnis zum Marketing</label>
        <p>Bitte wählen Sie aus, wie Sie von uns hören möchten:</p>
        <fieldset class="mc_fieldset gdprRequired mc-field-group" name="interestgroup_field">
        <label class="checkbox subfield" for="gdpr_33995"><input type="checkbox" id="gdpr_33995" name="gdpr[33995]" value="Y" class="av-checkbox gdpr"><span>E-Mail</span> </label>
        </fieldset>
        <p>In return for obtaining our study you agree to subscribe to our Equal Play newsletter. We will inform you in irregular intervals about topics, updates and news by our initiative Equal Play. You will receive the link to the direct download of our study with the registration confirmation to our free newsletter. You can of course unsubscribe at any time. For more information on how we process your personal data, please refer to our Privacy Policy.</p>
    </div>
    <div class="content__gdprLegal">
        <p>We use Mailchimp as our marketing platform. By clicking below to subscribe, you acknowledge that your information will be transferred to Mailchimp for processing. <a class="mc_link" href="https://mailchimp.com/legal/" target="_blank">Learn more about Mailchimp's privacy practices here.</a></p>
    </div>
</div>
    <div id="mce-responses" class="clear">
        <div class="response" id="mce-error-response" style="display:none"></div>
        <div class="response" id="mce-success-response" style="display:none"></div>
    </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_2eded37268a4b45ec83412a69_57eaef5d2b" tabindex="-1" value=""></div>
    <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
    </div>
</form>
</div>`
  };
}

export default function Subscribe() {
  return (
    <>
      {/* <div class='subscribe__container'>
        <h3 class='subscribe__headline'>
          Melde dich für den Newsletter an um die Studie/Thesis herunterzuladen.
        </h3>
        <p class='subscribe__text'>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex.
        </p> */}
      <div className='subscribe' dangerouslySetInnerHTML={createMarkup()} />
      {/* </div> */}
    </>
  );
}
