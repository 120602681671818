import React, { useState } from 'react';
import { Menu, Close } from '../assets/icon.js';
import '../styles/mobilenavigation.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { translations } from '../constants/lang/translation.js';

export default function MobileNavigation() {
  const [menuOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <div
      className='mobilenavigation'
      id='mobilenavigation'
      onBlur={() => setMenuOpen(false)}
    >
      <div
        onClick={() => setMenuOpen(!menuOpen)}
        className='mobilenavigation__icon--wrapper'
      >
        {!menuOpen && <Menu className='mobilenavigation__icon' />}
        {menuOpen && (
          <Close className='mobilenavigation__icon mobilenavigation__icon-close' />
        )}
      </div>
      {menuOpen && (
        <ul className='mobilenavigation__list'>
          <li>
            <Link
              to='/about'
              className='nav-link'
              onClick={() => setMenuOpen(!menuOpen)}
            >
              {t(translations.pages.about.title)}
            </Link>
          </li>
          {/* <li>
            <Link
              to='/download'
              className='nav-link'
              onClick={() => setMenuOpen(!menuOpen)}
            >
              {t(translations.pages.download.title)}
            </Link>
          </li> */}
          <li>
            <a
              href='mailto: sports-press@jvm.de'
              className='nav-link'
              onClick={() => setMenuOpen(!menuOpen)}
            >
              {t(translations.pages.contact.title)}
            </a>
          </li>
        </ul>
      )}
    </div>
  );
}
