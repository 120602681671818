import React from 'react';
import '../styles/landingpage.css';
import { useTranslation } from 'react-i18next';
import { translations } from '../constants/lang/translation';

export default function LandingPage() {
  const { t } = useTranslation();
  return (
    <div className='landingpage'>
      <div className='landingpage--download-wrapper'>
        <a href='/subscribe' className='landingpage__download--link'>
          {t(translations.actions.download)} <br />
          {t(translations.actions.thesis)}
        </a>
      </div>
      <div className='landingpage--logo-wrapper'>
        <img
          src={require('../assets/images/equal_logo.png')}
          className='landingpage--logo'
          alt=''
        />
      </div>
      <div className='landingpage--download-wrapper'>
        <a href='/subscribe' className='landingpage__download--link'>
          {t(translations.actions.download)} <br />
          {t(translations.actions.study)}
        </a>
      </div>
    </div>
  );
}
