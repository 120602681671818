import React from 'react';
import About from './About';
import Subscribe from './Subscribe';
import LandingPage from './LandingPage';
import Newsletter from './Newsletter';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/index.css';
import 'purecss/build/base.css';
import 'purecss/build/buttons.css';
import 'purecss/build/grids.css';
import 'purecss/build/grids-responsive.css';
import MobileFooter from '../components/MobileFooter';
import Imprint from './Imprint';
import Privacy from './Privacy';

class App extends React.Component {
  state = {
    isHome: true
  };

  constructor(props) {
    super(props);
    this.history = createBrowserHistory();
    this.history.listen((location, action) => {
      this.isHome();
    });
  }

  componentDidMount() {
    this.isHome();
    this.history.listen((location, action) => {
      this.isHome();
    });
  }

  isHome = () => {
    if (this.history.location.pathname === '/') {
      this.setState({
        isHome: true
      });
    } else {
      this.setState({
        isHome: false
      });
    }
  };
  render() {
    return (
      <div className={this.state.isHome ? 'container home' : 'container'}>
        <Router history={this.history}>
          <Header />

          <Switch>
            <Route exact path='/' component={LandingPage} />
            <Route exact path='/about' component={About} />
            <Route exact path='/subscribe' component={Subscribe} />
            <Route exact path='/imprint' component={Imprint} />
            <Route exact path='/privacy' component={Privacy} />
            <Route
              exact
              path='/download-study-thankyou'
              component={Newsletter}
            />
            <Redirect from='*' to='/' />
          </Switch>
          <Footer />
          <MobileFooter />
        </Router>
      </div>
    );
  }
}

export default App;
