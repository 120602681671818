import React, { Component } from 'react';

export default class Privacy extends Component {
  render() {
    return (
      <div className='wrapper'>
        <h1>Privacy Policy</h1>
        <h2>1. An overview of data protectionGeneral information</h2>
        <h3>General information</h3>{' '}
        <p>
          The following information will provide you with an easy to navigate
          overview of what will happen with your personal data when you visit
          this website. The term „personal data" comprises all data that can be
          used to personally identify you. For detailed information about the
          subject matter of data protection, please consult our Data Protection
          Declaration, which we have included beneath this copy.
        </p>
        <h3>Data recording on this website</h3>{' '}
        <p>
          <strong>
            The data on this website is processed by the operator of the
            website, whose contact information is available under section
            „Imprint" on this website.
          </strong>
        </p>{' '}
        <p>
          <strong>How do we record your data?</strong>
        </p>{' '}
        <p>
          We collect your data as a result of your sharing of your data with us.
          This may, for instance be information you enter into our contact form.
        </p>{' '}
        <p>
          Our IT systems automatically record other data when you visit our
          website.This data comprises primarily technical information (e.g. web
          browser, operating system or time the site was accessed). This
          information is recorded automatically when you access this website.
        </p>{' '}
        <p>
          <strong>What are the purposes we use your data for?</strong>
        </p>{' '}
        <p>
          A portion of the information is generated to guarantee the error free
          provision of the website. Other data may be used to analyse your user
          patterns.
        </p>{' '}
        <p>
          <strong>
            What rights do you have as far as your information is concerned?
          </strong>
        </p>{' '}
        <p>
          You have the right to receive information about the source, recipients
          and purposes of your archived personal data at any time without having
          to paya fee for such disclosures. You also have the right to demand
          that your data is rectified or eradicated. Please do not hesitate to
          contact us at any time under the address disclosed in section
          „Imprint" on this website if youhave questions about this or any other
          data protection related issues. You also have the right to log a
          complaint with the competent supervising agency.
        </p>{' '}
        <p>
          Moreover, under certain circumstances, you have the right to demand
          the restriction of the processing of your personal data. For details,
          please consult the Data Protection Declaration under section „Right to
          Demand Processing of Data."
        </p>
        <h3>Analysis tools and tools provided by third parties</h3>{' '}
        <p>
          There is a possibility that your browsing patterns will be
          statistically analysed when you visit this website. Such analyses are
          performed primarily with cookies and with what we refer to as analysis
          programmes. As a rule, the analyses of your browsing patterns are
          conducted anonymously; i.e. the browsing patterns cannot be traced
          back to you.
        </p>{' '}
        <p>
          You have the option to object to such analyses or you can prevent
          their performance by not using certain tools. For detailed information
          about the tools and about your options to object, please consult our
          Data Protection Declaration below.
        </p>
        <h2>2. Recording of data on this website</h2>
        <h3>Server log files</h3>{' '}
        <p>
          We automatically collect and store information in so-called server log
          files, which your browser communicates to us automatically. The
          information comprises:
        </p>{' '}
        <ul>
          {' '}
          <li>The type and version of browser used</li>{' '}
          <li>The used operating system</li> <li>Referrer URL</li>{' '}
          <li>The hostname of the accessing computer</li>{' '}
          <li>The time of the server inquiry</li> <li>The IP address</li>{' '}
        </ul>{' '}
        <p>This data is not merged with other data sources.</p>{' '}
        <p>
          This data is recorded on the basis of Art. 6 Sect. 1 lit. f GDPR. The
          operatorof the website has a legitimate interest in the technically
          error free depiction and the optimization of the operator's website.
          The server log files can be used for security purposes, e.g. to avoid
          overloading the servers (especially in the case of abusive attacks,
          so-called DDoS attacks) and to ensure the utilization of the servers
          and their stability. In order to achieve this, server log files must
          be recorded.
        </p>
        <p>
          Such data will be deleted automatically after seven days, unless a
          specific incident needs to be examined.
        </p>
        <h3>Request by e-mail, telephone or fax</h3>{' '}
        <p>
          If you contact us by e-mail, telephone or fax, your request, including
          all resulting personal data (name, request) will be stored and
          processed by usfor the purpose of processing your request. We do not
          pass these data on without your consent.
        </p>{' '}
        <p>
          The processing of these data is based on Art. 6 para. 1 lit. b GDPR,
          if your request is related to the execution of a contract or if it is
          necessary to carry out pre-contractual measures. In all other cases,
          the processing is based on your consent (Article 6 (1) a GDPR) and/or
          on our legitimate interests (Article 6 (1) (f) GDPR), since we have a
          legitimate interest in the effective processing of requests addressed
          to us.
        </p>{' '}
        <p>
          The data sent by you to us via contact requests remain with us until
          you request us to delete, revoke your consent to the storage or the
          purpose forthe data storage lapses (e.g. after completion of your
          request). Mandatory statutory provisions - in particular statutory
          retention periods - remain unaffected.
        </p>
        <h3>External Hosting</h3>{' '}
        <p>
          This website is hosted by an external service provider (host). Our
          host will only process your data according to our instructions.
        </p>
        <h3>Execution of a contract data processing agreement</h3>
        <p>
          In order to guarantee processing in compliance with data protection
          regulations, we have concluded a data processing agreement with our
          host.
        </p>
        <h2>3. General information and mandatory information</h2>
        <h3>Data protection</h3>{' '}
        <p>
          The operators of this website and its pages take the protection of
          your personal data very seriously. Hence, we handle your personal data
          as confidential information and in compliance with the statutory data
          protection regulations and this Data Protection Declaration.
        </p>{' '}
        <p>
          Whenever you use this website, a variety of personal information will
          be collected. Personal data comprises data that can be used to
          personally identify you. This Data Protection Declaration explains
          which data we collect as well as the purposes we use this data for. It
          also explains how, and for which purpose the information is collected.
        </p>{' '}
        <p>
          We herewith advise you that the transmission of data via the Internet
          (i.e. through e-mail communications) may be prone to security gaps. It
          is not possible to completely protect data against third party access.
        </p>
        <h3>
          Information about the responsible party (referred to as the
          „controller" in the GDPR)
        </h3>{' '}
        <p>The data processing controller on this website is:</p>{' '}
        <p>
          Jung von Matt/SPORTS GmbH
          <br />
          Glashüttenstrasse 79
          <br />
          20357 Hamburg
        </p>
        <p>
          Phone: +49 40 4321-0
          <br />
          E-mail:{' '}
          <a href='mailto:sports@jvm.de' style={{ color: '#fff' }}>
            sports@jvm.de
          </a>
        </p>
        <p>
          The controller is the natural person or legal entity that
          single-handedly or jointly with others makes decisions as to the
          purposes of and resources forthe processing of personal data (e.g.
          names, e-mail addresses, etc.).
        </p>
        <h3>Designation of a data protection officer as mandated by law</h3>{' '}
        <p>We have appointed a data protection officer for our company.</p>
        <p>
          Jung von Matt/SPORTS GmbH
          <br />
          Data Protection Officer
          <br />
          Glashüttenstraße 79
          <br />
          20357 Hamburg
        </p>
        <p>
          E-mail:{' '}
          <a href='mailto:datenschutz-extern@jvm.de' style={{ color: '#fff' }}>
            datenschutz-extern@jvm.de
          </a>
        </p>
        <h3>Revocation of your consent to the processing of data</h3>{' '}
        <p>
          A wide range of data processing transactions are possible only subject
          to your express consent. You can also revoke at any time any consent
          you have already given us. To do so, all you are required to do is
          sent us an informal notification via e-mail. This shall be without
          prejudice to the lawfulness of any data collection that occurred prior
          to your revocation.
        </p>
        <h3>
          Right to object to the collection of data in special cases; right to
          object to direct advertising (Art. 21 GDPR)
        </h3>{' '}
        <p>
          IN THE EVENT THAT DATA IS PROCESSED ON THE BASIS OF ART. 6 SECT. 1
          LIT. E OR F GDPR, YOU HAVE THE RIGHT TO AT ANY TIME OBJECT TO THE
          PROCESSING OF YOUR PERSONAL DATA BASED ON GROUNDS ARISING FROM YOUR
          UNIQUE SITUATION. THIS ALSO APPLIES TO ANY PROFILING BASED ON THESE
          PROVISIONS. TO DETERMINE THE LEGAL BASIS, ON WHICH ANY PROCESSING OF
          DATA IS BASED, PLEASE CONSULT THIS DATA PROTECTION DECLARATION. IF YOU
          LOG AN OBJECTION, WE WILL NO LONGER PROCESS YOUR AFFECTED PERSONAL
          DATA, UNLESS WE ARE IN APOSITION TO PRESENT COMPELLING PROTECTION
          WORTHY GROUNDS FORTHE PROCESSING OF YOUR DATA, THAT OUTWEIGH YOUR
          INTERESTS, RIGHTS AND FREEDOMS OR IF THE PURPOSE OF THE PROCESSING IS
          THE CLAIMING, EXERCISING OR DEFENCE OF LEGAL ENTITLEMENTS
          (OBJECTIONPURSUANT TO ART. 21 SECT. 1 GDPR).
        </p>{' '}
        <p>
          IF YOUR PERSONAL DATA IS BEING PROCESSED IN ORDER TO ENGAGE IN DIRECT
          ADVERTISING, YOU HAVE THE RIGHT TO AT ANY TIME OBJECT TO THE
          PROCESSING OF YOUR AFFECTED PERSONAL DATA FOR THE PURPOSES OF SUCH
          ADVERTISING. THIS ALSO APPLIES TO PROFILING TO THE EXTENT THAT IT IS
          AFFILIATED WITH SUCH DIRECT ADVERTISING. IF YOU OBJECT, YOUR PERSONAL
          DATA WILL SUBSEQUENTLY NO LONGER BE USED FOR DIRECT ADVERTISING
          PURPOSES (OBJECTION PURSUANT TO ART. 21 SECT. 2 GDPR).
        </p>
        <h3>Right to log a complaint with the competent supervisory agency</h3>{' '}
        <p>
          In the event of violations of the GDPR, data subjects are entitled to
          log a complaint with a supervisory agency, in particular in the member
          state where they usually maintain their domicile, place of work or at
          the place where the alleged violation occurred. The right to log a
          complaint is in effect regardless of any other administrative or court
          proceedings available as legal recourses.
        </p>
        <h3>Right to data portability</h3>{' '}
        <p>
          You have the right to demand that we hand over any data we
          automatically process on the basis of your consent or in order to
          fulfil a contract be handed over to you or a third party in a commonly
          used, machine readable format. If you should demand the direct
          transfer of the data to another controller, this will be done only if
          it is technically feasible.
        </p>
        <h3>SSL and/or TLS encryption</h3>{' '}
        <p>
          For security reasons and to protect the transmission of confidential
          content, such as purchase orders or inquiries you submit to us as the
          website operator, this website uses either an SSL or a TLS encryption
          programme. You can recognise an encrypted connection by checking
          whether the address line of the browser switches from „http://" to
          „https://" and also by the appearance of the lock icon in the browser
          line.
        </p>{' '}
        <p>
          If the SSL or TLS encryption is activated, data you transmit to us
          cannot be read by third parties.
        </p>
        <h3>Information about, rectification and eradication of data</h3>{' '}
        <p>
          Within the scope of the applicable statutory provisions, you have the
          right to at any time demand information about your archived personal
          data, their source and recipients as well as the purpose of the
          processing of yourdata. You may also have a right to have your data
          rectified or eradicated. Ifyou have questions about this subject
          matter or any other questions aboutpersonal data, please do not
          hesitate to contact us at any time at the address provided in section
          „General Information and Mandatory Information."
        </p>
        <h3>Right to demand processing restrictions</h3>{' '}
        <p>
          You have the right to demand the imposition of restrictions as far as
          the processing of your personal data is concerned. To do so, you may
          contact us at any time at the address provided in section „General
          Information andMandatory Information." The right to demand restriction
          of processing applies in the following cases:
        </p>{' '}
        <ul>
          {' '}
          <li>
            In the event that you should dispute the correctness of your data
            archived by us, we will usually need some time to verify this claim.
            During the time that this investigation is ongoing, you have the
            rightto demand that we restrict the processing of your personal
            data.
          </li>{' '}
          <li>
            If the processing of your personal data was/is conducted in an
            unlawful manner, you have the option to demand the restriction of
            the processing of your data in lieu of demanding the eradication of
            this data.
          </li>{' '}
          <li>
            f we do not need your personal data any longer and you need it to
            exercise, defend or claim legal entitlements, you have the right to
            demand the restriction of the processing of your personal data
            instead of its eradication.
          </li>{' '}
          <li>
            If you have raised an objection pursuant to Art. 21 Sect. 1 GDPR,
            your rights and our rights will have to be weighed against each
            other. As long as it has not been determined whose interests
            prevail,you have the right to demand a restriction of the processing
            of your personal data.
          </li>{' '}
        </ul>{' '}
        <p>
          If you have restricted the processing of your personal data, these
          data – with the exception of their archiving – may be processed only
          subject to your consent or to claim, exercise or defend legal
          entitlements or to protect the rights of other natural persons or
          legal entities or for importantpublic interest reasons cited by the
          European Union or a member state of the EU.
        </p>
        <h2>4. Social Media</h2>{' '}
        <p>
          We operate profiles on several social media websites (networks) so
          that we can communicate and connect with these networks’ users:
        </p>
        <ul>
          {' '}
          <li>
            Instagram: Instagram Inc. 1601 Willow Road, Menlo Park, CA, 94025,
            USA; Privacy Statement:{' '}
            <a
              href='http://instagram.com/about/legal/privacy'
              target='_blank'
              rel='noopener noreferrer'
              style={{ color: '#fff' }}
            >
              http://instagram.com/about/legal/privacy
            </a>
            .
          </li>{' '}
          <li>
            Facebook: Facebook Ireland Ltd, 4 Grand Canal Square, Grand Canal
            Harbour, Dublin 2, Ireland, Privacy Statement:
            https://www.facebook.com/about/privacy; Privacy Shield:{' '}
            <a
              href='https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC'
              target='_blank'
              rel='noopener noreferrer'
              style={{ color: '#fff' }}
            >
              https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC
            </a>
          </li>{' '}
          <li>
            LinkedIn: Social Network; Service Provider: LinkedIn Ireland
            Unlimited Company, Wilton Place, Dublin 2, Ireland; Privacy
            Statement:{' '}
            <a
              href='https://www.linkedin.com/legal/privacy-policy'
              target='_blank'
              rel='noopener noreferrer'
              style={{ color: '#fff' }}
            >
              https://www.linkedin.com/legal/privacy-policy
            </a>
            ; Privacy Shield:{' '}
            <a
              href='https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0'
              target='_blank'
              rel='noopener noreferrer'
              style={{ color: '#fff' }}
            >
              https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0
            </a>
          </li>{' '}
          <li>
            Twitter: Twitter Inc. 1355 Market Street, Suite 900, San Francisco,
            CA 94103, USA; Privacy Statement:{' '}
            <a
              href='https://twitter.com/de/privacy'
              target='_blank'
              rel='noopener noreferrer'
              style={{ color: '#fff' }}
            >
              https://twitter.com/de/privacy
            </a>
            , Privacy Shield:{' '}
            <a
              href='https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO'
              target='_blank'
              rel='noopener noreferrer'
              style={{ color: '#fff' }}
            >
              https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO
            </a>
          </li>{' '}
        </ul>{' '}
        <p>
          Such networks usually collect data on how users interact with this
          page and process this data outside the EU. To compensate for any
          detrimental effects this could have, these networks maintain Privacy
          Shield certifications, which commits it to observe EU data protection
          standards. The networks routinely use the personal data generated by
          the use of our page for the purposes of advertising, analytics, and
          market research, for example in order to develop a profile of your
          interests and habits so that it can display ads tailored specifically
          to you. Each network can also directly associate your activities on
          its page with your account of this very network(if you have one). In
          addition, the network provides us with a variety of aggregate data,
          from which it is generally not possible to infer information about
          individual users. We use the insights from this data to make our
          service more relevant for you. The lawful basis for processing is our
          legitimate interest (Art. 6 (1) (f) GDPR) in communicating with our
          users in a precisely targeted, effective way.
        </p>
        <h2>5. Newsletter</h2>{' '}
        <p>
          In order to download our “Equal Play” study for free, in return we
          request you to subscribe to our newsletter. You can unsubscribe at any
          time
        </p>{' '}
        <p>
          We send our newsletter with your consent only. In order to subscribe
          to ournewsletter, you need to enter an e-mail address and a name for
          the purpose of addressing you personally which will be validated by
          means of the <strong>double-opt-in procedure.</strong>
        </p>
        <p>
          This means that you will receive an e-mailafter registration asking
          you to confirm your registration. This confirmationis necessary so
          that nobody can register with an e-mail address he does not have
          access to. We log the registrations for the newsletter in order to
          comply with the legal requirements according to which we have to prove
          the registration process and your consent.{' '}
        </p>
        <p>
          Therefore, we store the registration and confirmation time as well as
          the IP address.
        </p>
        <p>
          We may store all deleted e-mail addresses for up to three years on the
          basis of our legitimate interests before we delete them in order to be
          able to prove a previously given consent. Hence, the processing of
          this data is limited to the purpose of a possible defence against
          claims only.
        </p>
        <p>
          The logging of the registration procedure takes place on the basis of
          our legitimate interests for the purposes of proving its lawful
          operation. Commissioning a service provider to send newsletters is
          also based on our legitimate interests in an efficient and secure
          dispatch system.
        </p>
        <p>
          The newsletters contain a so-called "web beacon” which is retrieved
          from the server of our dispatch service provider when you open the
          newsletter. In the course of this retrieval, technical information,
          such as information about the browser and your system, as well as your
          IP address (which enables us to roughly locate you) and the time of
          retrieval, are collected. We use this information for the technical
          and content-related improvementof our newsletter. This analysis also
          includes determining whether and when newsletters are opened.{' '}
        </p>
        <p>
          For technical reasons, this information can be assigned to the
          individual newsletter recipients. However, it is not our endeavour or
          that of our dispatch service provider to observe individual users.
          Rather, the evaluations serve us to recognise the reading habits of
          our users and to adapt our content to them.
        </p>
        <p>
          The evaluation of the newsletter and the performance measurement are
          carried out, subject to the express consent of the users, on the basis
          of ourlegitimate interests for the purposes of using a user-friendly
          and secure newsletter system, which serves both our business interests
          and the expectations of the users. To separately revoke your consent
          to the performance measurement is unfortunately not possible, in which
          case you had to unsubscribe from the entire newsletter.
        </p>
        <h4>What kind of personal data is processed?</h4>
        <p>
          Inventory data (e.g. your name), contact data (e.g. your e-mail
          address), meta data (e.g. your IP address, information regarding your
          device), usagedata (e.g. time of opening the newsletter).
        </p>
        <h4>Who is the data subject?</h4>
        <p>User having subscribed to our newsletter.</p>
        <p>
          Purposes of processing:
          <br />
          Direct marketing by e-mail.
        </p>
        <p>
          Legal basis:
          <br />
          Your consent (Art. 6 para. 1 lit. a GDPR), our legitimate interest
          (Art. 6 para1 lit f GDPR)
        </p>
        <p>
          Opt-out:
          <br />
          You can revoke your consent by unsubscribing from our newsletter at
          any time. You’ll find an unsubscribe link at the end of each
          newsletter. You can also drop us a line at xxx@jvm.de.
        </p>
        <p>
          Our dispatch service provider:“Mailchimp” – Rocket Science Group, LLC,
          675 Ponce De Leon Ave NE #5000, Atlanta, GA 30308, USA. You can find
          their privacy policy at{' '}
          <a
            href='https://mailchimp.com/legal/privacy/'
            target='_blank'
            rel='noopener noreferrer'
            style={{ color: '#fff' }}
          >
            https://mailchimp.com/legal/privacy/.
          </a>
          Rocket Science Group, LLC is Privacy Shield certified (
          <a
            href='https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG'
            target='_blank'
            rel='noopener noreferrer'
            style={{ color: '#fff' }}
          >
            (https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG)
          </a>
          which means that it ensures a data protection level adequate to that
          in the EU when processing data in the USA.
        </p>
      </div>
    );
  }
}
