import React, { Component } from "react";
import "../styles/specialbutton.css";

export default class SpecialButton extends Component {
  render() {
    const { title, link } = this.props;
    return (
      <div className="special-button">
        <a href={link}>{title}</a>
      </div>
    );
  }
}
