import React, { Component } from 'react';

export default class SocialIcons extends Component {
  render() {
    return (
      <div className='social-wrapper'>
        <a href='mailto:sports-press@jvm.de'>
          <img src={require('../assets/images/social-media/mail.png')} alt='' />
        </a>
        <a
          href='https://www.facebook.com/JungvonMattsports/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            src={require('../assets/images/social-media/facebook.png')}
            alt=''
          />
        </a>
        <a
          href='https://www.instagram.com/jvmsports/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            src={require('../assets/images/social-media/instagram.png')}
            alt=''
          />
        </a>
        <a
          href='https://twitter.com/jungvmattsports'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            src={require('../assets/images/social-media/twitter.png')}
            alt=''
          />
        </a>
        <a
          href='https://www.linkedin.com/company/jung-von-matt-sports/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            src={require('../assets/images/social-media/linkedin.png')}
            alt=''
          />
        </a>
      </div>
    );
  }
}
